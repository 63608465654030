import React from 'react';
import { Head } from './head';
import { Content } from './content';

const HomePage = () => (
    <React.Fragment>
        <Head />
        <Content />
    </React.Fragment>
);

export { HomePage as default };
